<template>
  <div id="offers" class="the-offers qv-section" v-if="content?.offers?.length">
    <div class="the-offers__container qv-section__container">
      <div class="the-offers__header qv-section__header">{{ content.header }}</div>
      <div class="the-offers__list">
        <div
          v-for="(offer, index) in content.offers"
          :key="index"
          class="the-offers__offer"
        >
          <div v-if="offer.image && !offer.video" class="the-offers__offer-video">
            <img :src="offer.image" alt="">
          </div>

          <div v-if="offer.video" class="the-offers__offer-video">
            <video
              :src="offer.video"
              controls
              muted
              :poster="offer.image"
            >
            </video>
          </div>

          <div class="the-offers__offer-info">
            <div class="the-offers__offer-header">
              <div v-if="offer.icon" class="the-offers__offer-icon">
                <img :src="offer.icon" alt="">
              </div>

              {{ offer.header }}
            </div>
            <div v-if="offer.text" class="the-offers__offer-text">
              {{ offer.text }}
            </div>

            <a
              v-if="false && offer.link"
              :href="offer.link"
              class="the-offers__offer-link"
            >
              {{ content['offer-link-text'] }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  computed: {
    content() {
      return this.$t('sections.our-offers');
    },
  },
};
</script>

<style lang="scss">
.the-offers {
  padding-bottom: 50px;

  overflow: hidden;

  &__offer {
    position: relative;
    display: flex;
    align-items: stretch;
    justify-content: space-between;

    margin-bottom: 40px;

    &::before {
      content: "";
      display: block;
      top: 0;
      left: 0;
      bottom: 0;
      width: 100vw;
      background: #f5f5f5;
      position: absolute;
      border-radius: 12px;
      z-index: -1;
    }

    &-video {
      position: relative;
      align-self: center;

      flex: 0 0 40%;
      max-width: 40%;

      // &::after {
      //   content: "\25B6";
      //   color: $white;
      //   position: absolute;
      //   right: 10px;
      //   font-size: 40px;
      //   text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      //   transform: scaleX(0.9);
      // }

      img,
      video {
        width: 100%;
      }
      img {
        border-radius: 10px;
      }
    }

    &-info {
      flex: 1 1 54%;
      max-width: 54%;
      padding: 10px 60px;

      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &-header {
      display: flex;
      align-items: center;

      font-size: 24px;
      line-height: 28px;
      font-weight: 500;

      color: #242121;

      margin-bottom: 16px;
    }

    &-icon {
      width: 1.83em;
      height: 1.66em;

      background: linear-gradient(180deg, rgba(255, 1, 1, 0.85) 0%, rgba(255, 94, 3, 0.65) 100%);
      border-radius: 4px;

      display: flex;
      align-items: center;
      justify-content: center;

      margin-right: 16px;
    }

    &-text {
      font-size: 18px;
      line-height: 21px;
      font-weight: 400;

      color: #242121;

      margin-bottom: 32px;
    }

    &-link {
      font-size: 18px;
      line-height: 138.19%;
      font-weight: 700;

      background: linear-gradient(180deg, #FF0000 0%, rgba(255, 138, 0, 0.68) 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;

      &::after {
        content: "\00BB";
        font-size: 1.5em;
        padding-left: 8px;
      }
    }

    &:nth-child(even) {
      flex-direction: row-reverse;

      &::before {
        left: auto;
        right: 0;
      }
    }
  }

  @include media-bp(tab) {
    &__offer {
      margin-bottom: 24px;

      &-video {
        flex: 0 0 35%;
        max-width: 35%;

        &::after {
          right: 8px;
          font-size: 26px;
        }
      }

      &-info {
        flex: 0 0 60%;
        max-width: 60%;

        padding: 10px 20px 10px 0;
      }

      &-header {
        font-size: 18px;
        margin-bottom: 8px;
      }

      &-text {
        margin-bottom: 12px;
      }

      &-text,
      &-link {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }

  @include media-bp(mob) {
    padding-bottom: 0;

    &__offer {
      flex-direction: column;
      margin-bottom: 48px;

      &:nth-child(even) {
        flex-direction: column;
        justify-content: center;
      }

      &:nth-child(even) & {
        &-video {
          margin-right: 0;
          margin-left: 0;
        }
      }

      &::before {
        content: none;
      }

      &-video {
        max-width: 100%;
        margin-right: 0;
        margin-bottom: 16px;
      }

      &-info {
        flex: 0 0 auto;
        max-width: 100%;
        padding: 0;
      }

      &-header {
        font-size: 24px;
        margin-bottom: 10px;
      }

      &-text {
        margin-bottom: 16px;
      }

      &-text,
      &-link {
        font-size: 18px;
        line-height: 21px;
      }

      &-text {
        margin-bottom: 16px;
      }
    }
  }
}
</style>
